
/**
 *
 */

+function($){

  page.initSection = function() {

  }

}(jQuery);

